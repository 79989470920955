import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import GlobalTokens from '@sainsburys-tech/tokens/dist/global/js/json/color/index.json';
import ColourBlock from '@components/colour-block';
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableRow, TableCell } from '@jsluna/table';
import { TypographyTableHeader, SainsburysTypographyTableBody, ArgosTypographyTableBody, HabitatTypographyTableBody, TuTypographyTableBody, NectarTypographyTableBody } from '@shared/tables/typography';
import HeadingDisplayTable from '@shared/tables/heading-display';
import { Tick, Cancel } from '@jsluna/icons';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const Tabs = makeShortcode("Tabs");
const TableTab = makeShortcode("TableTab");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#lunas-typography-principles"
            }}>{`Luna’s typography principles`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#the-luna-type-system"
            }}>{`The Luna type system`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#display-sizes-in-action"
            }}>{`Display sizes in action`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#tips-for-engineers-implementing-type"
            }}>{`Tips for engineers implementing type`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "lunas-typography-principles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lunas-typography-principles",
        "aria-label": "lunas typography principles permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Luna’s typography principles`}</h2>
    <GridList columns="3" mdxType="GridList">
  <GridListItem title="Characterful" titleElement="h3" border mdxType="GridListItem">
    Each brand has a unique font that forms a key part of its visual identity.
  </GridListItem>
  <GridListItem title="Flexible" titleElement="h3" border mdxType="GridListItem">
    The Luna system does not set certain sizes or weights for specific headers.
    Sizes can be chosen for any semantic heading according to what works best
    for the design.
  </GridListItem>
  <GridListItem title="Responsive" titleElement="h3" border mdxType="GridListItem">
    Luna typography automatically scales up and down to respond to different
    viewport sizes.
  </GridListItem>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "the-luna-type-system",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-luna-type-system",
        "aria-label": "the luna type system permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`The Luna type system`}</h2>
    <p>{`Each brand that uses the Luna system has its own individual font. But they are all applied to the Luna system, which maps the font to a hierarchy from smallest (‘`}<strong parentName="p">{`caption`}</strong>{`’) to largest (‘`}<strong parentName="p">{`Display 7`}</strong>{`’) with a consistent line height.`}</p>
    <h3>{`Responsive behaviour`}</h3>
    <p>{`In line with mobile first principles, the table below shows how type appears on `}<strong parentName="p">{`smaller`}</strong>{` screens using the web type stack. Each display option has a responsive alternative, which is the size it will adopt on screens larger than 720px.`}</p>
    <p>{`Whilst these styles are responsive by default, you can prevent this behaviour if needed.`}</p>
    <h3>{`Web type stack`}</h3>
    <Tabs mdxType="Tabs">
  <TableTab title="Sainsbury's" mdxType="TableTab">
    <TableHeader mdxType="TableHeader">
      <TypographyTableHeader mdxType="TypographyTableHeader" />
    </TableHeader>
    <TableBody mdxType="TableBody">
      <SainsburysTypographyTableBody mdxType="SainsburysTypographyTableBody" />
    </TableBody>
  </TableTab>
      <TableTab title="Argos" mdxType="TableTab">
  <TableHeader mdxType="TableHeader">
    <TypographyTableHeader mdxType="TypographyTableHeader" />
  </TableHeader>
  <TableBody mdxType="TableBody">
    <ArgosTypographyTableBody mdxType="ArgosTypographyTableBody" />
  </TableBody>
      </TableTab>
      <TableTab title="Tu" mdxType="TableTab">
  <TableHeader mdxType="TableHeader">
    <TypographyTableHeader mdxType="TypographyTableHeader" />
  </TableHeader>
  <TableBody mdxType="TableBody">
    <TuTypographyTableBody mdxType="TuTypographyTableBody" />
  </TableBody>
      </TableTab>
      <TableTab title="Habitat" mdxType="TableTab">
  <TableHeader mdxType="TableHeader">
    <TypographyTableHeader mdxType="TypographyTableHeader" />
  </TableHeader>
  <TableBody mdxType="TableBody">
    <HabitatTypographyTableBody mdxType="HabitatTypographyTableBody" />
  </TableBody>
      </TableTab>
      <TableTab title="Nectar" mdxType="TableTab">
  <TableHeader mdxType="TableHeader">
    <TypographyTableHeader mdxType="TypographyTableHeader" />
  </TableHeader>
  <TableBody mdxType="TableBody">
    <NectarTypographyTableBody mdxType="NectarTypographyTableBody" />
  </TableBody>
      </TableTab>
    </Tabs>
    <h3>{`iOS type stack`}</h3>
    <p>{`If you're a Sainsbury's colleague, the iOS type stack for Sainsbury's and Argos can be accessed through Figma:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/0QlUVMUdC46LgwPGk4CUm3/Sainsbury's---UI-Design-Library?node-id=6181%3A7202&t=Yr5TGM01gXI6JA9s-4"
        }}>{`Sainsbury's iOS type stack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/qiCnYNTI40R32wGFFIs2cN/DSYS-%E2%80%93-1044-Type-Tokens?node-id=3329%3A6022&t=lleyogi3Lqfdlanw-4"
        }}>{`Argos iOS type stack`}</a></li>
    </ul>
    <h3>{`Android font stack`}</h3>
    <p>{`If you're a Sainsbury's colleague, the Android font stack for Sainsbury's and Argos can be accessed through Figma:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/0QlUVMUdC46LgwPGk4CUm3/Sainsbury%27s---UI-Design-Library?node-id=6181%3A7237&t=Yr5TGM01gXI6JA9s-4"
        }}>{`Sainsbury's Android font stack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/he4CW2i10Gsc55dtIPP7TF/branch/qiCnYNTI40R32wGFFIs2cN/Argos---UI-Design-Library?node-id=3329%3A6045&t=SsvKG8WdlR28zqXF-1"
        }}>{`Argos Android font stack`}</a></li>
    </ul>
    <h3>{`Accessing our fonts within Figma`}</h3>
    <p>{`If you're a Sainsbury's colleague, all our brand fonts are available to use within the 'Text Styles' feature in Figma.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "display-sizes-in-action",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#display-sizes-in-action",
        "aria-label": "display sizes in action permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Display sizes in action`}</h2>
    <p>{`Below we’ve set out some sensible starting points for when to use different display sizes. You’ll see that the largest size suggested is Display 5.`}</p>
    <p>{`But remember that these are recommendations. There may be times when there’s a case to use different display sizes for headings, including Display 6 or 7. You’re free to do so according to the needs of your design and your users.`}</p>
    <h3>{`Suggested mapping of display sizes to headers`}</h3>
    <p>{`The table below shows how the type scale can be mapped to common HTML elements. There’s an alternative ‘small’ size mapping which may work better for more information dense screens (for example, some colleague applications).`}</p>
    <HeadingDisplayTable mdxType="HeadingDisplayTable" />
    <hr></hr>
    <h2 {...{
      "id": "tips-for-engineers-implementing-type",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tips-for-engineers-implementing-type",
        "aria-label": "tips for engineers implementing type permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Tips for engineers implementing type`}</h2>
    <h3>{`Using SASS`}</h3>
    <p>{`In Sass you can make use of the ln-font mixin to apply one of the type styles to a custom component (e.g. @include ln-font ('display-1');). Alternatively you can use the pithily named ln-font-size-and-line-height, which funnily enough just includes the font size and line-height properties without the family/weight/etc.`}</p>
    <h3>{`Using HTML`}</h3>
    <p>{`When building out HTML there is also a utility class for each of the type options. Making use of .ln-u-display-1, for example, will apply MaryAnn ExtraBold and the relevant font sizing to the element. In React you can similarly use the ≤Display1≥ component to apply this styling to its children.`}</p>
    <h3>{`Controlling the responsive style variant`}</h3>
    <p>{`To control when the responsive style variant is applied, you can change the value of $ln-fonts-scaled-breakpoint to a breakpoint option detailed on the layout page.`}</p>
    <p>{`If you want to prevent text from scaling, there are fixed variants of both the mixins (ln-font-fixed) and utility classes (.ln-u-display-1-fixed) that will stay at the initial style regardless of screen size. When using the React components you can apply the ‘fixed’ prop (`}<inlineCode parentName="p">{`<Display1 fixed/>`}</inlineCode>{`) to achieve this.`}</p>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      